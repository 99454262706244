import * as React from 'react';
import { Avatar, Dropdown, Navbar, Text } from '@nextui-org/react';
import { signOut } from 'next-auth/react';

interface AppNavBarUserDropdownProps {
  email: string;
  userImage: string;
}

/**
 * @name AppNavBarUserDropdown
 * @description tbd...
 */
export function AppNavBarUserDropdown(props: AppNavBarUserDropdownProps) {
  const { email, userImage } = props;

  // Hooks

  // Setup

  // Handlers
  const onDropdownAction = (action: React.Key) => {
    if (action === 'logout') {
      signOut();
    }
  };

  // Markup

  // Short Circuit

  return (
    <Navbar.Content
      css={{
        '@xs': {
          jc: 'flex-end',
          w: '12%',
        },
      }}
    >
      <Dropdown placement="bottom-right">
        <Navbar.Item>
          <Dropdown.Trigger>
            <Avatar
              bordered
              squared
              as="button"
              color="secondary"
              size="md"
              src={userImage}
            />
          </Dropdown.Trigger>
        </Navbar.Item>
        <Dropdown.Menu
          aria-label="User menu actions"
          color="secondary"
          disabledKeys={['profile', 'notifications']}
          onAction={(actionKey) => onDropdownAction(actionKey)}
        >
          <Dropdown.Item key="signed-in" css={{ height: '$18' }}>
            <Text b color="inherit" css={{ d: 'flex' }}>
              Signed in as
            </Text>
            <Text b color="inherit" css={{ d: 'flex' }}>
              {email}
            </Text>
          </Dropdown.Item>
          <Dropdown.Item key="profile" withDivider>
            Profile
          </Dropdown.Item>
          <Dropdown.Item key="notifications">Notifications</Dropdown.Item>
          <Dropdown.Item key="logout" withDivider color="error">
            Log Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar.Content>
  );
}

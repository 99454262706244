import * as React from 'react';
import {
  Button,
  Dropdown,
  Link,
  Loading,
  Navbar,
  Spacer,
} from '@nextui-org/react';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { routes, RoutesKey } from '@src/config/constants';
import { icons } from '@src/layouts/app/AppNavBarIcons';
import { useSessionUser } from '@src/layouts/app/hooks/useSessionUser';
import { AppNavBarUserDropdown } from './AppNavBarUserDropdown';

interface AppNavBarProps {}

/**
 * @name AppNavBarGroup
 * @description tbd...
 */
export function AppNavBar(_props: AppNavBarProps) {
  // const {} = props

  // Constants
  const collapseItems = [
    { path: routes.play, title: 'Play' },
    { path: routes.gameNew, title: 'New Match' },
    { path: routes.gameActive, title: 'Active Matches' },
    { path: routes.gameCompleted, title: 'Completed Matches' },
    { path: routes.friends, title: 'Friends' },
  ];

  // Hooks
  const { isLoadingSessionUser, sessionUser } = useSessionUser();
  const router = useRouter();

  // Setup
  const { pathname } = router;
  const userImage = sessionUser?.image ?? '';
  const email = sessionUser?.email ?? '';

  // Markup
  const renderUserDropdown = () => {
    if (!sessionUser) {
      return (
        <Button auto flat as={Link} href={routes.signIn}>
          Sign in
        </Button>
      );
    }

    return <AppNavBarUserDropdown email={email} userImage={userImage} />;
  };

  return (
    <Navbar variant="sticky">
      <Navbar.Brand>
        <Navbar.Toggle showIn="xs" aria-label="toggle navigation" />
        <Spacer x={1} />
        <NextLink href="/">
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            {icons.logoImage}
            {icons.logoText}
          </div>
        </NextLink>
      </Navbar.Brand>
      <Navbar.Content
        hideIn="xs"
        variant="underline-rounded"
        enableCursorHighlight
      >
        <Navbar.Link
          isActive={pathname.includes(routes.play)}
          href={routes.play}
        >
          Play
        </Navbar.Link>
        <Dropdown isBordered>
          <Navbar.Item>
            <Dropdown.Button
              auto
              light
              css={{
                dflex: 'center',
                px: 0,
                svg: { pe: 'none' },
              }}
              iconRight={icons.chevron}
              ripple={false}
            >
              Matches
            </Dropdown.Button>
          </Navbar.Item>
          <Dropdown.Menu
            aria-label="Games"
            css={{
              '& .nextui-dropdown-item': {
                // dropdown item title
                '& .nextui-dropdown-item-content': {
                  fontWeight: '$semibold',
                  w: '100%',
                },
                py: '$4',
                // dropdown item left icon
                svg: {
                  color: '$secondary',
                  mr: '$4',
                },
              },
              $$dropdownItemHeight: '70px',
              $$dropdownMenuWidth: '340px',
            }}
            onAction={(key) => {
              const routeKey = key as RoutesKey;
              if (!routes[routeKey]) {
                throw new Error(
                  `Navbar dropdown selected unknown route: ${routeKey}`,
                );
              }

              router.push(routes[routeKey]);
            }}
          >
            <Dropdown.Item
              key="gameNew"
              showFullDescription
              description="Start a new match with your friends. May the best human win!"
              icon={
                <Image
                  src="/icons/rocket-dynamic-premium.png"
                  width={40}
                  height={40}
                  priority={true}
                />
              }
            >
              New Match
            </Dropdown.Item>
            <Dropdown.Item
              key="gameActive"
              showFullDescription
              description="View all of your active matches. See how you stack up against your friends."
              icon={
                <Image
                  src="/icons/fire-dynamic-color.png"
                  width={40}
                  height={40}
                  priority={true}
                />
              }
            >
              Active Matches
            </Dropdown.Item>
            <Dropdown.Item
              key="gameCompleted"
              showFullDescription
              description="Dive into your completed matches. Your bragging rights are saved for all eternity."
              icon={
                <Image
                  src="/icons/trophy-dynamic-premium.png"
                  width={40}
                  height={40}
                  priority={true}
                />
              }
            >
              Completed Matches
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Navbar.Link
          isActive={pathname.includes(routes.friends)}
          href={routes.friends}
        >
          Friends
        </Navbar.Link>
      </Navbar.Content>
      <Navbar.Content>
        <Navbar.Item>
          {isLoadingSessionUser ? <Loading /> : renderUserDropdown()}
        </Navbar.Item>
      </Navbar.Content>
      <Navbar.Collapse showIn="xs">
        {collapseItems.map((item) => (
          <Navbar.CollapseItem key={item.title}>
            <Link
              color="inherit"
              css={{
                minWidth: '100%',
              }}
              href={item.path}
            >
              {item.title}
            </Link>
          </Navbar.CollapseItem>
        ))}
      </Navbar.Collapse>
    </Navbar>
  );
}

import * as React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createTheme, NextUIProvider } from '@nextui-org/react';

import { Session } from 'next-auth';
import { SessionProvider as AuthProvider } from 'next-auth/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import type { AppProps } from 'next/app';

import Head from 'next/head';

import { RecoilRoot } from 'recoil';
import { AppLayout } from '@src/layouts/app/AppLayout';
// import { AppMainContainer } from '@src/layouts/app/AppMainContainer';
import '../styles/globals.css';

// 2. Call `createTheme` and pass your custom values
const lightTheme = createTheme({
  type: 'light',
});

const darkTheme = createTheme({
  type: 'dark',
});

const isBrowser = typeof window !== 'undefined';
const apolloUri = isBrowser ? `${window.location.origin}/api/graphql` : '';
const client = new ApolloClient({
  cache: new InMemoryCache(),
  ssrMode: typeof window === 'undefined',
  uri: apolloUri,
});

export type ApplicationProps = AppProps<{ session?: Session }>;

export function Application(props: ApplicationProps) {
  const { Component, pageProps } = props;
  const { session } = pageProps;

  return (
    <>
      <Head>
        <title>Games with Friends</title>
        <meta name="description" content="Play daily games with your friends" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <AuthProvider session={session}>
        <ApolloProvider client={client}>
          <RecoilRoot>
            <NextThemesProvider
              defaultTheme="dark"
              attribute="class"
              value={{
                dark: darkTheme.className,
                light: lightTheme.className,
              }}
            >
              <NextUIProvider>
                <AppLayout>
                  <Component {...pageProps} />
                </AppLayout>
              </NextUIProvider>
            </NextThemesProvider>
          </RecoilRoot>
        </ApolloProvider>
      </AuthProvider>
    </>
  );
}

export default Application;

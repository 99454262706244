import * as React from 'react';
import { Container } from '@nextui-org/react';

import { Box } from '@src/layouts/app/AppBox';
import { AppNavBar } from '@src/layouts/app/AppNavBar';

interface AppLayoutProps {
  children: React.ReactNode;
}

/**
 * @name AppLayout
 * @description tbd...
 */
export function AppLayout(props: AppLayoutProps) {
  const { children } = props;

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxW: '100%',
        overflow: 'visible scroll',
        position: 'relative',
      }}
    >
      <AppNavBar />
      <Container id="main-container">{children}</Container>
    </Box>
  );
}

export const APP_NAME = 'Games with Friends';

export const routes = {
  friends: '/friends',
  game: '/game',
  gameActive: '/game/active',
  gameCompleted: '/game/completed',
  gameNew: '/game/new',
  gameView: '/game/view',
  inviteAccepted: '/friends/accept',
  play: '/play',
  signIn: '/sign-in',
  unauthorized: '/unauthorized',
} as const;

export type RoutesKey = keyof typeof routes;

export const DNP_VALUES = {
  WORD_GOLF: 7,
};

export const PAR_VALUES = {
  WORD_GOLF: 4,
};

export const GAME_NAMES = {
  WORD_GOLF: 'Word Golf',
};

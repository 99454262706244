import { useSession } from 'next-auth/react';

/**
 * @name useSessionUser
 * @description tbd...
 */
export function useSessionUser() {
  const { data, status } = useSession();
  const isLoading = status === 'loading';

  return {
    isLoadingSessionUser: isLoading,
    sessionUser: data?.user,
  };
}
